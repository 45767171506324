import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const GlobalSitePerformance: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage:
              'url(../../img/global-site-performance-header.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>Global Site Performance</h1>

          <h3 className="text-center">January to June</h3>
          <h5 className="text-muted text-center">2021 vs 2020</h5>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Traffic</th>
                  <th>Sales</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Page view</strong>
                      <br />
                      <span className="h3 text-success">38,60%</span>
                      <br />
                      <span className="text-muted">82 107 260 / 59 241 155</span>
                    </p>
                    <p>
                      <strong>Average time of sessions</strong>
                      <br />
                      <span className="h3 text-danger">-42,34%</span>
                      <br />
                      <span className="text-muted">00:01:23 / 00:02:24</span>
                    </p>
                    <p>
                      <strong>Number of sessions per user</strong>
                      <br />
                      <span className="h3 text-danger">-7,77%</span>
                      <br />
                      <span className="text-muted">1,20 / 1,30</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Quantity</strong>
                      <br />
                      <span className="h3 text-success">+222%</span>
                      <br />
                      <span className="text-muted">27 998 vs 12 629</span>
                    </p>
                    <p>
                      <strong>Value</strong>
                      <br />
                      <span className="h3 text-success">+208%</span>
                      <br />
                      <span className="text-muted">
                        36 906 007 CHF vs 17 765 111 CHF
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="spacer"></div>

          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default GlobalSitePerformance

export const pageQuery = graphql`
  query GlobalSitePerformance_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
